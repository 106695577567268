* {
  margin: 0;
  padding: 0;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #3c4043;
  /* height: 100vh; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app {
  height: 100vh;
  /* background: rgba(31, 30, 30, 0); */
  background: #3c4043;
}

.agora_video_player {
  /* border-radius: 8px; */
  background: #454545;
}

.MuiDialog-paper {
  position: absolute;
  bottom: 40px;
  right: "40%";
}

.MuiButtonBase-root,
.MuiIconButton-root {
  height: 40;
  width: 40;
}

.admin__video__container {
  display: flex;
  justify-content: center;
  /* max-width: 500px !important; */
}

@media only screen and (max-width: 390px) {
  .admin__video__container {
    max-height: 50vh !important;
  }
}

@media only screen and (max-width: 400px) {
  #localPlayerContainer {
    width: 100%;
  }
}

.MuiIconButton-label {
  color: #fff !important;
}

.MuiIconButton-root {
  height: "75px";
}

.MuiDialog-paperWidthSm {
  width: 340px !important;
  margin: 0 !important;
  margin-right: 16px !important;
}
